import React from "react"

function SvgComponent(props) {
  return (
    <svg width={28} height={20} viewBox="0 0 28 20" {...props}>
      <title>{"EMAIL"}</title>
      <path
        d="M27.2 6.991v10.827c0 .6-.23 1.113-.692 1.541a2.364 2.364 0 01-1.665.641H3.157a2.364 2.364 0 01-1.665-.641c-.461-.427-.692-.94-.692-1.541V6.991c.432.445.928.841 1.488 1.186 3.555 2.237 5.996 3.805 7.322 4.705.56.382 1.014.68 1.362.893.35.214.813.432 1.393.654.579.223 1.12.335 1.62.335h.03c.5 0 1.041-.112 1.62-.335.58-.222 1.044-.44 1.393-.654.348-.213.803-.511 1.362-.893 1.67-1.118 4.116-2.687 7.337-4.705A7.98 7.98 0 0027.2 6.991zM24.843.8c.638 0 1.19.214 1.657.64.467.428.7.942.7 1.542 0 .718-.24 1.404-.722 2.059a6.759 6.759 0 01-1.797 1.677l-6.895 4.432a65.68 65.68 0 00-.626.416c-.32.213-.584.386-.795.518-.212.132-.467.28-.766.443a4.63 4.63 0 01-.848.368c-.265.082-.51.123-.736.123h-.03c-.226 0-.471-.041-.736-.123a4.625 4.625 0 01-.848-.368 15.51 15.51 0 01-.766-.443 30.704 30.704 0 01-.795-.518c-.32-.214-.528-.352-.626-.416-.894-.582-2.18-1.411-3.86-2.489a688.413 688.413 0 01-3.02-1.943c-.609-.381-1.184-.907-1.724-1.575C1.07 4.475.8 3.855.8 3.282c0-.71.204-1.3.611-1.773C1.82 1.036 2.401.8 3.157.8h21.686z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
