import styled from "styled-components"

const StyledFooter = styled.div`
  width: initial;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 11vw;
  grid-row: 3/4;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0rem 4.5vw 3vw 4vw;
  z-index: 99997;
  display: ${props => (props.mobile ? "none" : "flex")};
  @media only screen and (max-width: 640px) {
    height: 7rem;
    width: 100%;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 2.5rem;
    position: absolute;
    display: ${props => (props.mobile ? "flex" : "none")};
    & .icon__container {
      width: 100%;
    }
  }
  & p {
    font-family: "Pano";
    font-weight: normal;
    font-style: normal;
    font-size: 1vw;
    margin: 0;
    color: black !important;
    display: none;
    @media only screen and (max-width: 640px) {
      font-size: 12px;
      margin-bottom: 20px;
      text-align: center;
      transform: rotate(0);
      display: inline-block;
    }
  }
  & .icon__container {
    width: initial;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &.mobile-hide {
      @media only screen and (max-width: 640px) {
        display: none;
      }
    }
    & .icon {
      width: 2vw;
      height: 2vw;
      position: relative;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
      & svg {
        position: absolute;
        width: 2vw;
        height: 2vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & * {
          fill: black;
        }
      }
    }
    @media only screen and (max-width: 640px) {
      flex-direction: row;
      width: 6rem;
      & .icon {
        width: 1.5rem;
        height: 1.5rem;
        & svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
`

export default StyledFooter
