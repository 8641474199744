import React from "react"
import EmailSvg from "../images/svgs/EmailSvg"
import LinkedinSvg from "../images/svgs/LinkedIn"

import StyledFooter from "../styles/StyledFooter"

const Footer = ({ mobile }) => {
  return (
    <StyledFooter mobile={mobile}>
      <p>Cameron Jones - Portfolio</p>
      <div className="icon__container">
        <a href="mailto:camawjones@gmail.com?Subject=Hey%20Cam" target="_blank">
          <div className="icon">
            <EmailSvg />
          </div>
        </a>
        <a
          href="https://www.linkedin.com/in/cameron-jones-88a77093/"
          target="_blank"
          rel="noopenner norefferer"
        >
          <div className="icon">
            <LinkedinSvg />
          </div>
        </a>
      </div>
    </StyledFooter>
  )
}

export default Footer
