import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import CircleType from "circletype"
import { _, _s } from "../utils/globals/helperFunctions"
import MagnetEffect from "../utils/effects/MagnetEffect"
import StyledHeader from "../styles/StyledHeader"
import RockNRoll from "../images/svgs/RockNRoll"
import LinkedIn from "../images/svgs/LinkedIn"
import HandWithCircleText from "../images/svgs/HandWithCircleText"

const Header = ({ siteTitle }) => {
  useEffect(() => {
    const container = _(".circle__container")

    new MagnetEffect(container)
  }, [])
  return (
    <StyledHeader speed={4}>
      <div className="header-contents__container">
        <div className="left-header__container">
          <Link to="/">
            <h1>CJ</h1>
          </Link>
        </div>
        <a href="mailto:camawjones@gmail.com?Subject=Hey%20Cam" target="_blank">
          <div className="circle__container">
            <HandWithCircleText />
          </div>
        </a>
      </div>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
