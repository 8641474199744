import styled from "styled-components"

const StyledLandingBackground = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.1;
  & .gatsby-image-wrapper {
    height: 100%;
  }
  & img {
    object-fit: cover;
  }
`

export default StyledLandingBackground
