import styled from "styled-components"

const StyledBorderBoxes = styled.div`
  & .box--border {
    position: absolute;
    right: 0;
    top: 0;
    border: 0.05vw solid black;
    width: 100%;
    height: 100%;
    background: white;

    &:nth-child(3) {
      transform: translate(2.22vw, 2.22vw);
      z-index: 1000;
    }
    &:nth-child(1) {
      transform: translate(1.48vw, 1.48vw);
      z-index: 1002;
    }
    &:nth-child(2) {
      transform: translate(0.74vw, 0.74vw);
      z-index: 1003;
    }
  }
`

export default StyledBorderBoxes
