import React from "react"
import StyledPageSwiper from "../styles/StyledPageSwiper"
//swiperLeft
//bgColor
//swiped

const PageSwiper = ({ swiperLeft }) => {
  return <StyledPageSwiper swiperLeft={swiperLeft} className="page-swiper" />
}

export default PageSwiper
