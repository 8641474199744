import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
a {
  text-decoration: none;
  color: inherit;
  &:hover {
      text-decoration: none;
  color: inherit;
  }
}

canvas {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    pointer-events: none;
}

html {
  font-size: 100%;
}

@media only screen and (min-width: 1700px) and (max-width: 1800px) {
  html {
    font-size: 120%;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2200px) {
  html {
    font-size: 130%;
  }
}

@media only screen and (min-width: 2200px) and (max-width: 3000px) {
  html {
    font-size: 150%;
  }
}

@media only screen and (min-width: 3000px) and (max-width: 4000px) {
  html {
    font-size: 220%;
  }
}

@media only screen and (min-width: 4000px) and (max-width: 4500px) {
  html {
    font-size: 250%;
  }
}

@media only screen and (min-width: 4500px) {
  html {
    font-size: 290%;
  }
}

`
