export const _ = elem => {
  return document.querySelector(elem)
}

export const _s = elems => {
  return [...document.querySelectorAll(elems)]
}

export const MathUtils = {
  lineEq: (y2, y1, x2, x1, currentVal) => {
    // y = mx + b
    var m = (y2 - y1) / (x2 - x1),
      b = y1 - m * x1
    return m * currentVal + b
  },
  lerp: (a, b, t) => {
    return (1 - t) * a + t * b
  },
  getRandomFloat: (min, max) => (Math.random() * (max - min) + min).toFixed(2),
}

let d

if (typeof window !== `undefined`) {
  d = window.matchMedia("(min-width: 640px)")
}

export const calcWinSize = device => {
  const progressBar = document.querySelector(".progress-bar")
  if (progressBar) {
    if (d.matches) {
      progressBar.style.opacity = 1
      device(true)
      return true
    } else {
      progressBar.style.opacity = 0
      device(false)
      return false
    }
  }
}

export const getMousePos = e => {
  let posx = 0
  let posy = 0
  if (!e) e = window.event
  if (e.pageX || e.pageY) {
    posx = e.pageX
    posy = e.pageY
  } else if (e.clientX || e.clientY) {
    posx =
      e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
    posy =
      e.clientY + document.body.scrollTop + document.documenElement.scrollTop
  }

  return { x: posx, y: posy }
}

export const mediaCheck = device => {
  d.addListener(() => calcWinSize(device))
}
