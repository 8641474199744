import React from "react"
import StyledBorderBoxes from "../styles/StyledBorderBoxes"

const BorderBoxes = () => {
  return (
    <StyledBorderBoxes>
      <div className="box--border" />
      <div className="box--border" />
      <div className="box--border" />
    </StyledBorderBoxes>
  )
}

export default BorderBoxes
