import styled, { keyframes } from "styled-components"

const rotate = keyframes`
0% {
  transform: rotate(0)
}
100% {
  transform: rotate(360deg)
}

`

const mobileRotate = keyframes`
0% {
  transform: rotate(0) scale(0.7)
}
100% {
  transform: rotate(360deg) scale(0.7)
}

`

const StyledHeader = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0);
  height: 5vw;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99997;
  @media only screen and (max-width: 1024px) {
    position: absolute;
  }
  & .linkedin--svg {
    width: 2.2vw;
    height: 2.2vw;
    position: relative;
  }

  & .header-contents__container {
    margin: 0px auto;
    width: 100%;
    padding: 0 3vw 0 4vw;
    height: 6vw;
    position: relative;
    display: flex;
    justify-content: space-between;
    & .left-header__container {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 12vw;
      height: 100%;
      align-items: center;
      padding-top: 2.3rem;
    }

    & h1,
    a {
      color: black;
      font-family: "Pano";
      font-size: 2vw;
      margin: 0;
      text-decoration: none;
    }
    & .circle__container {
      position: relative;
      position: relative;
      width: 6vw;
      height: 6vw;
      padding: 1vw 0 0 0vw;
      cursor: pointer;
      & svg {
        width: 6vw;
        height: 6vw;
      }
      & .link-cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1111;
      }
      & .rock-n-roll {
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%);
      }

      & .circle--center {
        position: relative;
        font-size: 1rem;
        animation: ${props => `${props.speed}s`} linear 1s infinite ${rotate};
      }
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      /* & .circle__container {
        width: 6rem;
      } */
    }
    @media only screen and (max-width: 1024px) {
      & .circle__container {
        width: 5rem;
        height: 6rem;
        & svg {
          width: 5rem;
          height: 6rem;
        }
      }
      & .linkedin--svg {
        width: 1.6rem;
        height: 1.6rem;
      }
      & h1 {
        font-size: 18px;
      }
    }
    @media only screen and (max-width: 640px) {
      width: 75%;
      padding: 0;
      height: 8.5rem;

      & .left-header__container {
        width: 6rem;
        padding-top: 0;
      }
      & h1 {
        font-size: 18px;
      }
      & .circle__container {
        width: 5rem;
        height: 7rem;
        padding: 10px 0 0 0;
      }

      & .circle--center {
        animation: ${props => `${props.speed}s`} linear 1s infinite
          ${mobileRotate};
      }
    }
  }
`

export default StyledHeader
