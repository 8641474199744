import styled from "styled-components"

//swiperLeft
//bgColor
//swiped

const StyledPageSwiper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${props => props.swiperLeft};
  background: linear-gradient(
    to bottom,
    #ff4242 3.34%,
    #f9b93d 15.92%,
    #f3f656 29.01%,
    #55fe51 45.12%,
    #5bf5fe 55.69%,
    #745dff 72.81%,
    #fb4dff 82.88%,
    #ff4242 100%
  );
  transition: left 1s cubic-bezier(0.74, 0.02, 0.23, 1);
  z-index: 99999;
`

export default StyledPageSwiper
